import Vue from 'vue'
import App from './App.vue'
import vuetify from '../shared/plugins/vuetify'
import i18n from './i18n.js'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

Vue.config.productionTip = false
Vue.use(VueCookieAcceptDecline)

const browserLocale = navigator.language || navigator.userLanguage
const locale = browserLocale.split('-')[0]
console.debug('current locale', locale)
i18n.locale = locale // Set the new locale
vuetify.framework.lang.current = locale // update Vuetify's language setting
// console.log(vuetify, vuetify.lang)

new Vue({
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
