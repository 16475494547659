<template>
  <div>
    <!-- <v-banner class="mb-6">
      <div class="display-1 text-center pt-2">Register</div>
    </v-banner> -->
    <v-banner class="mb-6">
      <a :href="landingURL" target="_blank">
        <v-img
          :lazy-src="logoUri"
          max-height="80"
          max-width="260"
          placeholder
          class="mx-auto"
          :src="logoUri"
        ></v-img>
      </a>
      <!-- <v-img
        :lazy-src="logoUrlFixed"
        max-height="80"
        max-width="260"
        placeholder
        :src="logoUrlFixed"
        class="mx-auto"
      ></v-img> -->
      <div class="display-1 text-center pt-2">{{ $t('login.register') }}</div>
    </v-banner>
    <v-card-text>
      <v-form v-model="userDataFormValid" action="/api/openid/register" method="post">
        <input type="hidden" name="authRequestId" :value="authRequestId" />
        <input type="hidden" name="locale" :value="locale" />
        <v-text-field
          v-model="username"
          prepend-icon="mdi-account"
          name="username"
          :label="$t('login.username')"
          :rules="[rules.required]"
          type="text"
        ></v-text-field>

        <v-text-field
          v-model="contactEmail"
          name="contactEmail"
          prepend-icon="mdi-email-outline"
          :label="$t('login.contactEmail')"
          :rules="[rules.required,rules.email]"
          type="email"
        ></v-text-field>

        <!-- <v-text-field
          id="password"
          v-model="password"
          :rules="[rules.required]"
          prepend-icon="mdi-lock"
          name="password"
          label="Password"
          type="password"
        ></v-text-field>

        <v-text-field
          id="passwordConfirm"
          v-model="passwordConfirm"
          :rules="[rules.required, rules.matchPassword]"
          prepend-icon="mdi-lock"
          name="passwordConfirm"
          label="Password confirm"
          type="password"
        ></v-text-field> -->

        <vuetifyPassword
          id="password"
          v-model="password"
          :rules="[rules.required, rules.minPasswordMax]"
          name="password"
          :label="$t('login.password')"
          prepend-icon="mdi-lock"
        ></vuetifyPassword>
        <vuetifyPassword
          id="passwordConfirm"
          v-model="passwordConfirm"
          :rules="[rules.required, rules.minPasswordMax, rules.matchPassword]"
          name="passwordConfirm"
          :label="$t('login.passwordConfirm')"
        ></vuetifyPassword>

        <v-checkbox v-model="acceptTerms" :rules="[rules.required]">
          <template #label>
            <div>
              {{ $t('login.iAcceptTerms') }}
              <a :href="tosUri ? tosUri : '/terms'" target="_blank" @click.stop>{{ $t('login.termsConditions') }}</a>
              {{ $t('login.and') }}
              <a :href="policyUri ? policyUri : '/policy'" target="_blank" @click.stop>{{ $t('login.privacyPolicy') }}</a>
            </div>
          </template>
        </v-checkbox>
        <input type="hidden" name="acceptTerms" :value="acceptTerms ? 'true' : 'false'" />
        <div class="newsletter-subscription">
          <v-checkbox v-model="subscribedToNewsletter" :label="$t('login.newsletterCheckbox')"></v-checkbox>
          <input type="hidden" name="subscribedToNewsletter" :value="subscribedToNewsletter ? 'true' : 'false'" />
        </div>
        <v-row align="center" class="mt-6">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!userDataFormValid"
            rounded class="px-16"
            type="submit"
          >
            {{ $t('login.register') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </v-form>
    </v-card-text>
  </div>
</template>

<script>
// import logoUrl from '../../logomark-black.png'
import VuetifyPassword from '../../shared/plugins/components/VuetifyPassword.vue'

export default {

  components: {
    VuetifyPassword
  },
  props: {
    authRequestId: String,
    logoUri: String,
    tosUri: String,
    policyUri: String
  },
  data() {
    return {
      landingURL: import.meta.env.VITE_LANDING_URL,
      username: '',
      contactEmail: '',
      password: '',
      locale: '',
      acceptTerms: true,
      subscribedToNewsletter: false,
      userDataFormValid: false,
      minCharacters: 1,
      minPasswordCharacters: 4,
      maxCharacters: 255,
      // logoUrlFixed: logoUrl,
      rules: {
        required: (v) => !!v || !isNaN(parseFloat(v)) || this.$t('validation.form.requiredField'),
        minMax: (v) => ((v && v.length >= this.minCharacters) && (v && v.length <= this.maxCharacters)) || this.$t('validation.form.mustBeBetween', { min: this.minCharacters, max: this.maxCharacters }),
        minPasswordMax: (v) => ((v && v.length >= this.minPasswordCharacters) && (v && v.length <= this.maxCharacters)) || this.$t('validation.form.mustBeBetween', { min: this.minPasswordCharacters, max: this.maxCharacters }),
        email: (v) => ((/^\S+@\S+\.\S+$/).test(v)) || this.$t('validation.form.emailMustBeValid'),
        matchPassword: (v) => v === this.password || this.$t('validation.form.passwordDoNotMatch')
      }

    }
  }, /*
  computed: {
    termsLinks() {
      if (this.policyUri
  } */
  mounted() {
    const browserLocale = navigator.language || navigator.userLanguage
    this.locale = browserLocale.split('-')[0]
  }
}
</script>

<style>
</style>
