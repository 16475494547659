import createAxios from './api'
import i18n from '@/i18n.js'

const PATH = 'openid'

export default {

    async sendOtp(dto) {
        const axios = await createAxios(false)
        const resp = await axios.post(`${PATH}/actions/sendOtp`, dto)
        return resp.data
    }
}

