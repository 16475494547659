<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex align-self-center xs12 sm8 md4>
            <v-snackbar v-model="errorShowed" top timeout="-1" color="error">
              <v-icon dark left large>mdi-block-helper</v-icon>
              {{ errorMsg }}
              <v-btn text @click="errorShowed = false">
                {{ $t('main.close') }}
              </v-btn>
            </v-snackbar>
            <v-card class="elevation-12" mx-12>
              <login-form
                v-if="loginMode"
                :client-display-name="clientDisplayName"
                :client-type="clientType"
                :auth-request-id="authRequestId"
                :logo-uri="logoUri"
                :brokers="brokers"
                :two-factor-info="twoFactorInfo"
              ></login-form>
              <registration-form v-else :auth-request-id="authRequestId" :logo-uri="logoUri" :tos-uri="tosUri" :policy-uri="policyUri"></registration-form>
              <v-spacer></v-spacer>
              <v-row v-if="!twoFactorInfo" align="center" justify="center" class="ma-6">
                <div class="subtitle-1 mb-6">
                  {{ loginModeText }}
                  <a href="#" @click="loginMode = !loginMode">{{ loginModeAnchor }}</a>
                </div>
              </v-row>

              <div v-if="(Boolean(policyUri) || Boolean(clientUri)) || Boolean(tosUri)">
                <v-row align="center" justify="center">
                  <v-btn icon @click="toggleUriList = !toggleUriList">
                    <v-icon right>
                      {{ uriListToggleIcon }}
                    </v-icon>
                  </v-btn>
                  <a href="#" @click="toggleUriList = !toggleUriList">
                    <div
                      class="body-2"
                    >{{ $t('login.importantLinks') }}
                    </div></a>
                </v-row>
                <div v-if="toggleUriList" class="my-2">
                  <v-row justify="center">
                    <div v-if="!!clientUri" class="caption">
                      Your target is this site:
                      <a href="clientUri"></a>
                    </div>
                  </v-row>
                  <v-row v-if="!!policyUri" justify="center">
                    <div class="caption"><a :href="policyUri">{{ $t('login.privacyPolicy') }}</a></div>
                  </v-row>
                  <v-row v-if="!!tosUri" justify="center" class="mb-4">
                    <div class="caption"><a :href="tosUri">{{ $t('login.termsConditions') }}</a></div>
                  </v-row>
                </div>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
        <vue-cookie-accept-decline
          :debug="false"
          :disableDecline="true"
          :showPostponeButton="false"
          @clicked-accept="cookieClickedAccept"
          @clicked-decline="cookieClickedDecline"
          @clicked-postpone="cookieClickedPostpone"
          @removed-cookie="cookieRemovedCookie"
          @status="cookieStatus"
          elementId="dedicatedIDCookieBanner"
          position="bottom"
          ref="dedicatedIDCookieBanner"
          transitionName="slideFromBottom"
          type="bar"
        >
          <!-- Optional -->
          <template #postponeContent>&times;</template>

          <!-- Optional -->
          <template #message>
            {{ $t("cookie.bannerDescription") }}
            <a :href="cookiePolicyURL" target="_blank">{{
              $t("cookie.learnMore")
            }}</a>
          </template>

          <!-- Optional -->
          <template #declineContent>{{ $t("cookie.optOut") }}</template>

          <!-- Optional -->
          <template #acceptContent>{{ $t("cookie.acceptPolicy") }}</template>
        </vue-cookie-accept-decline>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LoginForm from './components/LoginForm.vue'
import RegistrationForm from './components/RegisterForm.vue'
import logoUrl from '../logomark-black.png'
import ForgeErrors from './ForgeErrors'
import VueCookieAcceptDecline from "vue-cookie-accept-decline";

export default {
  components: {
    LoginForm,
    RegistrationForm, VueCookieAcceptDecline
  },
  data() {
    return {
      loginMode: true,
      clientDisplayName: '',
      clientType: '',
      authRequestId: '',
      logoUri: '',
      tosUri: '',
      cookiePolicyURL: import.meta.env.VITE_COOKIEPOLICY_URL,
      clientUri: 'adssdf',
      policyUri: '',
      toggleUriList: false,
      brokers: [],
      twoFactorInfo: null,
      locale: 'en',
      error: null,
      errorShowed: false,
      errorMsg: ''
    }
  },
  computed: {
    loginModeText() {
      if (this.loginMode) {
        return this.$t('login.dontHaveAccountYet')
      }
      return this.$t('login.alreadyHaveAccount')
    },
    loginModeAnchor() {
      if (this.loginMode) {
        return this.$t('login.signUp')
      }
      return this.$t('login.login')
    },
    uriListToggleIcon() {
      if (this.toggleUriList) return 'mdi-chevron-up'
      return 'mdi-chevron-down'
    }
  },
  beforeMount() {
    const serverData = JSON.parse(document.getElementById('serverData').text)
    console.debug('serverData', serverData)
    this.authRequestId = serverData.authRequestId
    this.clientDisplayName = serverData.clientDisplayName
    this.clientType = serverData.clientType
    this.logoUri = serverData.logoUri
    if (!this.logoUri || this.logoUri.length === 0) {
      // this.logoUri = '/logomark-black.png'
      this.logoUri = logoUrl
    }
    this.clientUri = serverData.clientUri
    this.policyUri = serverData.policyUri
    this.tosUri = serverData.tosUri
    this.brokers = serverData.brokers
    this.twoFactorInfo = serverData.twoFactorInfo
    this.error = serverData.error
    this.errorShowed = this.error
    this.errorMsg = this.error ? this.getErrorMessage(this.error) : ''
  },
  methods: {
    getErrorMessage(error) {
      // let errorMsg = this.$t('toastMessage.errorWhileRetrievingData')
      let errorMsg = ''
      if (error.code !== undefined) {
        console.debug('error.code', error.code)
      } else {
        console.debug('error', error)
      }
      if (error.code !== undefined) {
        const errorcode = error.code.toUpperCase()
        switch (errorcode) {
          case ForgeErrors.INVALID_AUTH_REQUEST:
            errorMsg = this.$t('backendErrors.INVALID_AUTH_REQUEST')
            break
          case ForgeErrors.INVALID_CODE:
            errorMsg = this.$t('backendErrors.INVALID_CODE')
            break
          case ForgeErrors.INVALID_USERNAME_OR_PASSWORD:
            errorMsg = this.$t('backendErrors.INVALID_USERNAME_OR_PASSWORD')
            break
          case ForgeErrors.OTP_NEEDED:
            errorMsg = this.$t('backendErrors.OTP_NEEDED')
            break
          default:
            errorMsg = this.$t('backendErrors.SERVER_ERROR') + ' ' + this.$t('backendErrors.ADDITIONAL_DETAILS', { datetime: new Date().toUTCString(), code: errorcode })
        }
      } else {
        // errorMsg = this.getNotHandledErrorMessage(error)
        errorMsg = this.$t('backendErrors.SERVER_ERROR')
      }
      return errorMsg
    },
    getNotHandledErrorMessage(error) {
      // console.log(this)
      if (error.response !== undefined) {
        if (error.response.status === 500) {
          return this.$t('backendErrors.SERVER_ERROR') + ' ' + this.$t('backendErrors.ADDITIONAL_DETAILS', { datetime: new Date().toUTCString(), code: error.response.status })
        } else {
          return this.$t('backendErrors.SERVER_ERROR') + ' ' + this.$t('backendErrors.ADDITIONAL_DETAILS', { datetime: new Date().toUTCString(), code: error.response.status })
        }
      } else {
        // timeout error
        if (error.code === 'ECONNABORTED') {
          return this.$t('errors.timeOut') + ' ' + this.$t('errors.additionalDetails', { datetime: new Date().toUTCString(), msg: error.message })
        } else {
          return this.$t('backendErrors.SERVER_ERROR') + ' ' + this.$t('backendErrors.ADDITIONAL_DETAILS', { datetime: new Date().toUTCString(), code: error.code })
        }
      }
    }
  }

}
</script>

<style>
</style>
