<template>
  <div>
    <v-banner class="mb-6">
      <a :href="landingURL" target="_blank">
        <v-img
          :lazy-src="logoUri"
          max-height="80"
          max-width="260"
          placeholder
          class="mx-auto"
          :src="logoUri"
        ></v-img>
      </a>
      <!-- <v-img
        :lazy-src="logoUrlFixed"
        max-height="80"
        max-width="260"
        placeholder
        :src="logoUrlFixed"
        class="mx-auto"
      ></v-img> -->
      <div class="display-1 text-center pt-2">{{ clientDisplayName ? $t('login.loginTo', { clientDisplayName: clientDisplayName}) : $t('login.loginToWithoutClientDisplayName') }} </div>
    </v-banner>
    <v-card-text>
      <v-form ref="loginForm" action="/api/openid/login" method="post">
        <input type="hidden" name="authRequestId" :value="authRequestId" />
        <input type="hidden" name="type" :value="authType" />

        <input v-if="twoFactorInfo" type="hidden" name="credentialId" :value="selectedCredentialID" />
        <input v-if="twoFactorInfo && selectedToggle != toggles[3].value" type="hidden" name="code" :value="Code" />
        <input v-if="twoFactorInfo && selectedToggle === toggles[3].value" type="hidden" name="webAuthnAssertionResponse" :value="webAuthnAssertionResponse" />

        <v-text-field
          v-if="!twoFactorInfo"
          v-model="username"
          prepend-icon="mdi-account"
          name="username"
          :label="$t('login.username')"
          type="text"
        ></v-text-field>
        <v-text-field
          v-if="!twoFactorInfo"
          id="password"
          v-model="password"
          prepend-icon="mdi-lock"
          name="password"
          :label="$t('login.password')"
          type="password"
        ></v-text-field>
        <v-checkbox v-if="!twoFactorInfo"
          v-model="rememberMe"
          :label="$t('login.rememberMe')"
          name="rememberMe"
          value="true"
        >
        </v-checkbox>
        <div class="d-flex flex-column justify-center">
          <v-btn v-if="!twoFactorInfo" rounded color="primary" class="px-16" type="submit">{{ $t('login.loginButton') }}</v-btn>
          <template v-if="twoFactorInfo">
            <v-row>
              <v-col class="mb-1">
                <v-select
                  v-model="selectedToggle"
                  :items="toggles"
                  :label="$t('login.selectAuthMethod')"
                  item-text="title"
                  item-value="value"
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="selectedToggle && (selectedToggle === toggles[0].value)" class="mt-1 mb-0">
              <v-col cols="12">
                <v-label>{{ $t('login.selectYourDevice') }}</v-label>
              </v-col>
              <v-col class="mb-0">
                <v-select
                  v-model="selectedCredentialTOTPCredentialID"
                  :items="twoFactorInfo.totp"
                  item-text="device"
                  item-value="credentialId"
                  :label="$t('login.selectDevice')"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="selectedToggle && (selectedToggle != toggles[1].value) && (selectedToggle != toggles[3].value)" class="mt-1 mb-0">
              <v-col class="mb-0">
                <v-textarea
                  v-model="Code"
                  :label="$t('login.twofactorCode')"
                  rows="1"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row v-if="selectedToggle && (selectedToggle != toggles[1].value)" >
              <v-col>
                <v-btn @click="submitForm" color="primary" :disabled="!selectedToggle || (selectedToggle != toggles[3].value && !this.Code)">{{ $t('login.submit') }}</v-btn>
              </v-col>
            </v-row>

            <v-row v-if="selectedToggle === toggles[1].value">
              <v-col>
                <v-label>
                  {{ $t('login.otpCodeEnter') }}
                </v-label>
                <v-otp-input
                  v-model="OTP"
                  length="6"
                  class="mt-3 mb-3"
                  @finish="onFinish"
                ></v-otp-input>
                <v-label>
                  {{ $t('login.otpExplainer') }}
                </v-label>
              </v-col>
            </v-row>
          </template>
        </div>
      </v-form>
    </v-card-text>
    <v-card-text v-if="brokers && brokers.length">
      <div class="d-flex align-center">
        <v-divider class="mr-2"></v-divider>or<v-divider class="ml-2"></v-divider>
      </div>
      <v-btn v-for="item in brokers" :key="item.name" class="my-2" block color="primary" :href="item.href">Log in with {{ item.displayName }}</v-btn>
    </v-card-text>
  </div>
</template>

<script>
// import logoUrl from '../../logomark-black.png'
import openid from '@/services/api/openid.js'
import {
  get,
  parseRequestOptionsFromJSON,
} from '@github/webauthn-json/browser-ponyfill'

export default {

  props: {
    clientDisplayName: String,
    clientType: String,
    authRequestId: String,
    logoUri: String,
    brokers: Array,
    twoFactorInfo: Object
  },
  data() {
    return {
      username: '',
      password: '',
      rememberMe: 'true',
      // logoUrlFixed: logoUrl,
      selectedCredentialID: '',
      selectedCredentialTOTPCredentialID: '',
      selectedWebAuthCredentialID: null,
      webAuthnAssertionResponse: null,
      OTPVisible: false,
      OTP: '',
      Code: '',
      enabled2FACount: 0,
      landingURL: import.meta.env.VITE_LANDING_URL,
      toggles: [
        { title: 'TOTP', value: 'totp', disabled: false },
        { title: 'EMAIL OTP', value: '', disabled: false },
        { title: 'RECOVERY CODE', value: '', disabled: false },
        { title: 'WEB AUTH', value: 'webauth', disabled: false }
      ],
      selectedToggle: null,
    }
  },
  computed: {
    authType() {
      if (!this.twoFactorInfo) {
        return 'PASSWORD'
      } else {
        if (this.selectedToggle === 'webauth') {
          return 'WEBAUTHN'
        } else {
          return 'CODE'
        }
      }
    },
  },
  async mounted() {
    //console.debug('loginform mount', this.twoFactorInfo)

    //this.selectedCredentialID = this.twoFactorInfo.emailOtpCredentialId
    if (!this.twoFactorInfo) {
      return
    }

    if (this.twoFactorInfo.totp && this.twoFactorInfo.totp.length > 0) {
      //this.toggles[0].value = this.twoFactorInfo.totp.credentialId
      this.selectedToggle = this.toggles[0].value
      this.enabled2FACount++
    } else {
      this.toggles[0].disabled = true
    }

    if (this.twoFactorInfo.emailOtpCredentialId) {
      this.toggles[1].value = this.twoFactorInfo.emailOtpCredentialId
      this.enabled2FACount++
      if (!this.selectedToggle) {
        this.selectedToggle = this.toggles[1].value
      }
    } else {
      this.toggles[1].disabled = true
    }

    if (this.twoFactorInfo.recoveryCode && this.twoFactorInfo.recoveryCode.credentialId) {
      this.toggles[2].value = this.twoFactorInfo.recoveryCode.credentialId
      this.enabled2FACount++
      if (!this.selectedToggle) {
        this.selectedToggle = this.toggles[2].value
      }
    } else {
      this.toggles[2].disabled = true
    }

    if (this.twoFactorInfo.webAuthnGetOptions && this.twoFactorInfo.webAuthnGetOptions.publicKey.allowCredentials.length > 0) {
      //this.toggles[3].value = this.twoFactorInfo.webauth.credentialId
      this.enabled2FACount++
      if (!this.selectedToggle) {
        this.selectedToggle = this.toggles[3].value
      }
    } else {
      this.toggles[3].disabled = true
    }

    const dto = {
      authRequestId: this.authRequestId,
    }
    try {
      if (this.twoFactorInfo.emailOtpCredentialId && !this.twoFactorInfo.emailOtpSent) {
        await openid.sendOtp(dto)
      } else {
        console.log("OTP code already sent")
      }
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    onFinish(OTPTyped) {
      // console.debug('OTP filled', OTPTyped)
      this.OTP = OTPTyped
      this.Code = OTPTyped
      this.selectedCredentialID = this.selectedToggle
      this.$nextTick(() => {
        this.$refs.loginForm.$el.submit()
      })
    },
    async submitForm() {
      if (this.selectedToggle) {
        //console.log('Submitting:', this.selectedToggle, this.Code)
        if (this.selectedToggle === 'totp') {
          this.selectedCredentialID = this.selectedCredentialTOTPCredentialID
        } else
        if (this.selectedToggle === 'webauth') {
          const reqOptions = parseRequestOptionsFromJSON(this.twoFactorInfo.webAuthnGetOptions)
          //console.log('reqOptions', reqOptions)
          const webAuthnAssertionResponseObj = await get(reqOptions)
          //console.log("webauth output obj", this.webAuthnAssertionResponseObj)
          this.webAuthnAssertionResponse = JSON.stringify(webAuthnAssertionResponseObj)
          //console.log("webauth output json stringify", this.webAuthnAssertionResponse)
        } else
        {
          this.selectedCredentialID = this.selectedToggle
        }
        this.$nextTick(() => {
          this.$refs.loginForm.$el.submit()
        })
      } else {
        console.warn('Please select a toggle and enter a code.');
      }
    },
  }

}
</script>

<style>
</style>
